<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
        <div>
        <div class="tabs-content-secondblock right-tab" >
            <div class="">
                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                    <div class="breadcrumbactive ml-2">
                        <div>
                            <span>Profile</span>
                            <div>Basic Information</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mr-2" style="margin-right: 5px;margin-left: 10px;">
                <div class="" style="background-color: #fff;border-radius: 0px 6px 6px 0px;">
                    <div class="d-flex justify-content-between align-items-center basic-info" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                        <h6 class="subHeaderTitle my-2 text-white pl-6">
                            <i class="crmnew-user iconsize-16 iconsize" style="font-size: 14px; color: #fff;"></i>
                            <span class="pl-2 fs-14 fw-500 profile-text">Basic Information</span>
                          <sup class="fs-14 ml-1 mandatory">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="The options for this field are configurable in the Settings page"
                              placement="right"
                            >
                              <i class="icon-information text-secondary" style="font-size: 14px"></i>
                            </el-tooltip>
                          </sup>
                        </h6>
                        <div v-if="$route.params.id == loggedInUser.user_id" style="margin-right: 10px;">
                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                <button v-if="!edit_enable" class="btn btn-icon pointer ml-2 mr-2 text-capitalize" @click="enableEdit" style="padding: 2px 6px !important;background: rgb(255, 255, 255);border-radius: 3px !important;">
                                    <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                                    <span class="mx-1 mt-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="ml-2 mr-1 mb-3">
                        <div class="row pb-50" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                        <!-- <div class="row"> -->
                            <div class="col-lg-12">
                                <div class="infoHeader px-6 pt-4" style="padding-bottom: 50px !important;">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-3">
                                                <div class="d-flex new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">First Name<span class="pl-1 text-danger">*</span></div>
                                                    <div class="w-100" style="position: relative;">
                                                        <input name='first_name' v-validate="'required'" class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" maxlength="20" v-model="edit_user_data.first_name" :class="{readonly : !edit_enable}">
                                                        <div class="d-flex justify-content-between align-items-center" >
                                                            <span class="validation" v-show="errors.has('first_name')">
                                                                This field is required
                                                            </span>
                                                            <span v-if="edit_enable" class="fs-13 text-right d-block ml-auto" >{{edit_user_data.first_name.length}}/20</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-3">
                                                <div class="d-flex new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">Middle Name</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <input class="input-field-underline" style="padding-top: 6px !important;" maxlength=20 :readonly="!edit_enable" v-model="edit_user_data.middle_name" :class="{readonly : !edit_enable}">
                                                        <div v-if="edit_enable" class="d-flex justify-content-end align-items-center" >
                                                            <span class="fs-13 text-right d-block">{{edit_user_data.middle_name.length}}/20</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-4">
                                                <div class="d-flex new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">Last Name<span class="pl-1 text-danger">*</span></div>
                                                    <div class="w-100" style="position: relative;">
                                                        <input name='last_name' v-validate="'required'" maxlength="20" class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.last_name" :class="{readonly : !edit_enable}">
                                                        <div class="d-flex justify-content-between align-items-center" >
                                                            <span class="validation" v-show="errors.has('last_name')">
                                                                This field is required
                                                            </span>
                                                            <span v-if="edit_enable" class="fs-13 text-right d-block ml-auto">{{edit_user_data.last_name.length}}/20</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-4">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">
                                                        Date of Birth
                                                    </div>
                                                    <div class="w-100" >
                                                        <div style="position: relative; width: 100%;">
                                                            <date-picker class="profile-date-picker calendar-edit-per input-field-underline pt-0 pb-0 pr-0" :disabled="!edit_enable" v-model="edit_user_data.date_of_birth" value-type="format" lang="en" :not-after="new Date()" 
                                                            :clearable="false" width="100%" confirm name="date_of_birth" v-validate="'required'"
                                                            :editable="false" style="color: #303031 !important;padding-bottom: 0px !important;padding-left: 2px !important;"
                                                            type="date" format="MM/DD/YYYY"></date-picker>
                                                        </div>
                                                        <span class="validation" v-show="errors.has('date_of_birth')">
                                                            This field is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-6">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text position-relative">User Access
                                                        <sup class="fs-14 ml-1 mandatory" v-if="!edit_enable">
                                                            <!-- <sup v-if="loggedInUser.user_role != 'owner'" class="fs-14 ml-1 mandatory"> -->
                                                            <el-tooltip class="item" effect="dark"
                                                                content="Please contact system administrator to change the user access"
                                                                placement="right">
                                                                <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                            </el-tooltip>
                                                        </sup>
                                                    </div>
                                                    <div class="w-100 input-field-underline" style="padding-top:6px !important;font-size:13px;font-weight:400;color: #303031 !important; text-transform: capitalize; " :class="{readonly : !edit_enable}">
                                                        <span v-if="edit_user_data.user_access== 'super_admin'"> Super Admin</span>
                                                        <span v-else> {{edit_user_data.user_access || "-"}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-6" >
                                                <div class="d-flex new-contactView align-items-start" >
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">About</div>
                                                    <div class="w-100">
                                                        <input class="input-field-underline" style="padding-top: 6px !important; padding-right: 10px !important;" maxlength="100" :readonly="!edit_enable" v-model="edit_user_data.about" :class="{readonly : !edit_enable}"/>
                                                        <!-- <textarea class="input-field-underline" style="padding-top: 6px !important; padding-right: 10px !important;resize: none;" maxlength="200" :readonly="!edit_enable" v-model="edit_user_data.about" /> -->
                                                        <div v-if="edit_enable" class="d-flex justify-content-end align-items-center" >
                                                            <span class="fs-13 text-right d-block">{{edit_user_data.about.length}}/100</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="mt-6">
                                        <div class="d-flex align-items-center new-contactView" style="position: relative;">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">Gender</div>
                                            <div v-if="edit_enable" class="w-100" style="font-weight:600;position: relative;">
                                                <div>
                                                    <div v-if="edit_enable" class="form-check form-check-inline mt-2 mr-2">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" :readonly="!edit_enable" @change="maleGender" v-validate="'required'" v-model="edit_user_data.gender" name="vendor_type" value="Male">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description">Male</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable" class="form-check form-check-inline mt-2" style="position: relative;">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" :readonly="!edit_enable" @change="femaleGender" v-model="edit_user_data.gender" v-validate="'required'" name="vendor_type" value="Female">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description">Female</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable" class="form-check form-check-inline mt-2" style="position: relative;">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" :readonly="!edit_enable" @change="customGenderChange" v-model="edit_user_data.gender" v-validate="'required'" name="vendor_type" value="Custom">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description" :class="{'active-custom' :custom_gender_active}">Others</span>
                                                            <span v-if="custom_gender_active" class="ml-1">
                                                                <input v-if="custom_gender_active" name="gender" :maxlength="maxlengthcustomname" v-validate="'required'" type="text" v-model="edit_user_data.custom_gender" style="border-bottom: solid 0.5px #cdd2da !important;width: 162px;">
                                                                <span v-if="edit_enable && custom_gender_active" class="fs-13 d-block ml-auto" style=" text-align: end;">{{edit_user_data.custom_gender.length}}/15</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="w-100">
                                                <div v-if="edit_user_data.custom_gender.length>0" class="input-field-underline" :class="{readonly : !edit_enable}">
                                                    {{edit_user_data.custom_gender}}
                                                </div>
                                                <div v-else class="input-field-underline" :class="{readonly : !edit_enable}">
                                                    {{edit_user_data.gender}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6" v-if="loggedInUser.account_type == 'business'">
                                        <div class="d-flex  new-contactView align-items-start" style="position: relative;">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-4">Job Description
                                                <sup class="fs-14 ml-1 mandatory" v-if="!edit_enable">
                                                    <el-tooltip class="item" effect="dark"
                                                        content="Please enter job description"
                                                        placement="right">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup>
                                            </div>
                                            <div class="w-100">
                                                <!-- <input class="input-field-underline" style="padding-top: 6px !important;" maxlength=20 :readonly="!edit_enable" v-model="edit_user_data.job_description"> -->
                                                <textarea class="input-field-underline" maxlength="200" style="padding-top: 6px !important;padding-right: 10px !important;resize: none;" :readonly="!edit_enable" v-model="edit_user_data.job_description" :class="{readonly : !edit_enable}" />
                                                <div v-if="edit_enable" class="d-flex justify-content-end align-items-center" >
                                                    <span class="fs-13 text-right d-block">{{(edit_user_data.job_description).length}}/200</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6">
                                        <div class="d-flex new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text mt-1">Signature Name</div>
                                            <div class="w-100" style="position: relative;">
                                                <input name='signature_name' onkeydown="return /[a-z, ]/i.test(event.key)" maxlength="100" class="input-field-underline word-break" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.signature_details.name" :class="{readonly : !edit_enable}">
                                                <div v-if="edit_enable" class="d-flex justify-content-end align-items-center" >
                                                    <span class="fs-13 text-right d-block">{{(edit_user_data.signature_details.name).length}}/100</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-8">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text">Signature</div>
                                            <div class="w-100" style="position: relative;">
                                                <div v-if="(edit_user_data.signature_details.signature).length == 0 && !add_signature && !edit_enable">
                                                    <!-- <i class="icon icon-plus text-white" style="color: #fff !important;"></i> -->
                                                    <button @click="addSignature" :disabled="!edit_enable" class="btn btn-success text-white" style="text-transform: capitalize;">+ Add Signature</button>
                                                    <!-- <button v-else @click="addSignature" :disabled="!edit_enable" class="btn btn-success text-white" style="text-transform: capitalize;background-color: #128807 !important;">+ Add Signature</button> -->
                                                </div>
                                                <div v-else class="d-flex align-items-center">
                                                    
                                                        <div class="row signature-box ml-0 mr-2" v-if="upload_in_progress" style="width: 209px;border: 1px solid #303031;border-radius: 5px;">
                                                            <div class="pl-20 text-align-center" style="z-index: 999 !important;">
                                                                <div class="flex-container">
                                                                    <half-circle-spinner :animation-duration="1000" :size="40" color="#00448b" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!upload_in_progress && (edit_user_data.signature_details.signature).length > 0" class="position-relative signature-box d-flex align-items-start justify-content-between" style="width: 209px;">
                                                        <div  style="width: 100%;height: 42.5px; z-index: 111;" class="text-center d-flex align-items-center justify-content-center">
                                                            <div v-if="(edit_user_data.signature_details.signature).length > 0" class="w-100 h-100 pointer">
                                                                <img style="width: 100%;" :src="edit_user_data.signature_details.signature" class="w-100 h-100">
                                                            </div>
                                                            <div :class="{'d-none':(edit_user_data.signature_details.signature).length > 0}">
                                                                <label for="input_signature_pic">
                                                                    <div class="">
                                                                        <!-- <span class="fs-14 text-secondary pointer">Add Signature</span> -->
                                                                        <!-- <span class="fs-14 text-secondary pointer btn-new-primarys" style="color: #fff !important;">Add<b style="color: #00448b;">i</b>Signature</span> -->
                                                                    </div>
                                                                </label>
                                                                <input id="input_signature_pic" class="v-hidden w-0" type="file" name="input_profile_pic" @change="uploadSignatureAttachment" accept="image/*"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="{'d-none':(edit_user_data.signature_details.signature).length > 0}">
                                                        <label for="input_signature_pic">
                                                            <div class="">
                                                                <!-- <span class="fs-14 text-secondary pointer">Add Signature</span> -->
                                                                <!-- <span class="fs-14 text-secondary pointer btn-new-primarys" style="color: #fff !important;">Add<b style="color: #00448b;">i</b>Signature</span> -->
                                                            </div>
                                                        </label>
                                                        <input id="input_signature_pic" class="v-hidden w-0" type="file" name="input_profile_pic" @change="uploadSignatureAttachment" accept="image/*"/>
                                                    </div>
                                                    <div class="d-flex justify-content-end align-items-center" v-if="($route.params.id == loggedInUser.user_id) && edit_enable && (edit_user_data.signature_details.signature).length > 0" >
                                                        <div class="ml-2 pointer" @click="showDeleteConfirm(edit_user_data.signature_details.id)">
                                                            <i class="icon icon-delete text-secondary"></i>
                                                            <!-- <span style="color: #e82828;font-weight: 400;font-size: 14px;">Remove</span> -->
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-new-secondary ml-2 p-2" style="line-height: 1;height: 30px;" @click="triggerFileUpload" v-if="($route.params.id == loggedInUser.user_id) && edit_enable && (edit_user_data.signature_details.signature).length > 0">
                                                        Update Signature
                                                    </button>
                                                    <button v-if="($route.params.id == loggedInUser.user_id) && edit_enable && (edit_user_data.signature_details.signature).length == 0 && !upload_in_progress" @click="triggerFileUpload" class="btn btn-success text-white" style="text-transform: capitalize;">+ Add Signature</button>
                                                    <!-- <button class="btn btn-success text-white p-2" style="line-height: 1;height: 30px;" @click="triggerFileUpload" v-if="($route.params.id == loggedInUser.user_id) && edit_enable && (edit_user_data.signature_details.signature).length == 0">
                                                        + Add Signature
                                                    </button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                        <button class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress" @click="editDisable" style="width: 120px;border-radius: 5px;">Cancel</button>
                        <button class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" @click="updateInfo" style="width: 120px;border-radius: 5px;">Save
                            <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <two-step-verification v-if="accept_two_step_verification" @hideTwoStepVerification="hideTwoStepVerification" modal_name="two-step-verification"></two-step-verification>
        <delete-confirm-popup v-if="delete_confirm" @hideConfirm="hideConfirm" type="Signature" @deleteConfirm="deleteConfirm" modal_name="delete_confirm" ></delete-confirm-popup>
    </div>
</template>
<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import moment from "moment";
    import { SweetModal } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import DatePicker from 'vue2-datepicker'
    import Element from 'element-ui'
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import { User } from '../mixins/user.js';
    import profile from '../mixins/profile.js';
    import axios from 'axios';
    const DeleteConfirmPopup = () => import('./DeleteConfirmPopup');
    // const AcceptTwoStepVerification = () => import('./AcceptTwoStepVerification');
    import {TwoStepVerification} from '@digicollect_front_end/digi-fe-library'
    export default {
        props: [],
        components:{
            DatePicker,
            Element,
            HalfCircleSpinner,
            SweetModal,
            TwoStepVerification,
            DeleteConfirmPopup,
            HollowDotsSpinner
        },
        data() {
            return {
                maxlengthcustomname:15,
                custom_gender_active: false,
                about_editing: false,
                basic_editing: false,
                warning_msg: '',
                success_msg: '',
                add_signature: false,
                edit_user_data: {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    date_of_birth: '',
                    about: '',
                    job_description: '',
                    gender: '',
                    custom_gender: '',
                    user_access: 'Owner',
                    signature_details: {
                        id: "",
                        size: "",
                        name: "",
                        signature: ""
                    }
                },
                edit_about: false,
                gender_radio: '',
                ajax_call_in_progress: false,
                user_data: null,
                stored_user_data: null,
                delete_signature: false,
                edit_enable: false,
                upload_in_progress: false,
                save_api_call_in_progress: false,
                accept_two_step_verification: false,
                custom_gender_value: '',
                delete_confirm: false,
                signature_id: '',
                stringified_response: "",
                left_tab: false,
                correctPath: ""
            };
        },
        mixins: [User, profile],
        methods: {
            moment(date) {
                return moment(date);
            },
            hideTwoStepVerification() {
                this.accept_two_step_verification = false;
            },
            hideConfirm() {
                this.delete_confirm = false;
            },
            showDeleteConfirm(id) {
                this.signature_id = id
                this.delete_confirm = true;
                setTimeout(() => {
                    this.$modal.show('delete_confirm');
                }, 500);
            },
            deleteConfirm() {
                if(this.signature_id === undefined || this.signature_id === null || this.signature_id === '') {
                    this.add_signature = false;
                    this.delete_signature = true;
                    this.edit_user_data.signature_details.signature = '';
                    this.delete_confirm = false;
                } else {
                    this.deleteSignatureSuccess(this.signature_id);
                }
            },
            async checkTwoFactorVerification() {
                try {
                    let params = {
                    login_id: this.loggedInUser.login_id
                    }
                    let response = await this.checkLogin(params);
                    if(response.status_id == 1 && response.is_two_step_verification == false) {
                        if(this.fromRoute.path == "/"){
                            this.accept_two_step_verification = true;
                            setTimeout(() => {
                                this.$modal.show("two-step-verification");
                            }, 500);
                        }
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            enableEdit() {
                this.edit_enable = true
                if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                    this.edit_user_data.gender = 'Custom'
                    this.custom_gender_active = true
                }
            },
            editDisable() {
                this.edit_enable = false
                this.custom_gender_active = false
                this.custom_gender_active = false;
                this.edit_user_data.first_name = this.stored_user_data.first_name;
                this.edit_user_data.middle_name = this.stored_user_data.middle_name;
                this.edit_user_data.last_name = this.stored_user_data.last_name;
                this.edit_user_data.date_of_birth = this.stored_user_data.date_of_birth.split('-')[1]+'/'+this.stored_user_data.date_of_birth.split('-')[2]+'/'+this.stored_user_data.date_of_birth.split('-')[0];
                this.edit_user_data.about = this.stored_user_data.about;
                this.edit_user_data.job_description = this.stored_user_data.job_description;
                this.edit_user_data.gender = this.stored_user_data.gender;
                this.edit_user_data.custom_gender = this.stored_user_data.custom_gender;
                this.custom_gender_value = JSON.parse(JSON.stringify(this.stored_user_data.custom_gender));
                this.edit_user_data.user_access = this.stored_user_data.user_access;
                if(Object.keys(this.stored_user_data.signature_details).length > 0) {
                    this.edit_user_data.signature_details = this.stored_user_data.signature_details
                } 
                else {
                    this.edit_user_data.signature_details = {
                        id: "",
                        size: "",
                        name: "",
                        signature: ""
                    }
                }
                if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                    this.edit_user_data.gender = 'Custom'
                }
            },
            customDeselected() {
                if (this.custom_gender_active) {
                    this.custom_gender_active = false;
                    this.edit_user_data.gender = "Male";
                }
            },
            // deleteSignature(id) {
            //     this.$swal({
            //         title: 'Alert',
            //         text: "Are you sure want to delete ?",
            //         type: 'warning',
            //         icon: 'warning',
            //         showCloseButton: true,
            //         showCancelButton: true,
            //         confirmButtonText:'YES',
            //         cancelButtonText:'NO'
            //     }).then((result) => {
            //         if (result.value) {
            //             if(id === undefined || id === null || id === '') {
            //                 this.add_signature = false;
            //                 this.delete_signature = true;
            //                 this.edit_user_data.signature_details.signature = '';
            //             } else {
            //                 this.deleteSignatureSuccess(id);
            //             }
            //         }
            //     })
            // },
            async deleteSignatureSuccess(id) {
                try {
                    let params = {
                        id: id
                    };
                    let response = await this.deleteSignatureById(params);
                    this.add_signature = false;
                    this.delete_signature = false;
                    // this.edit_enable = false
                    this.edit_user_data.signature_details = {
                        id: "",
                        size: "",
                        name: "",
                        signature: ""
                    };
                    this.success_msg = response.message;
                    this.$refs.success_modal.open();
                    this.delete_confirm = false;
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        // this.personalInfoData()
                    }, 1000);
                } catch (e) {
                    this.delete_confirm = false;
                    // this.edit_enable = false
                    // console.log(e);
                    this.warning_msg = e;
                    this.$refs.warning_modal.open();
                }
            },
            triggerFileUpload() {
                document.getElementById('input_signature_pic').click();
            },
            addSignature() {
                this.add_signature = true;
            },
            uploadSignatureAttachment(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    if(input.files[0].size > 5120*1024) {
                        this.warning_msg = "File size can't be greater than 5 MB";
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    } else {
                        this.getResumableURLprofile(input.files[0], attachment);
                    }
                }
            },
            getResumableURLprofile(file, attachment) {
                this.upload_in_progress = true;
                let data = {
                    file: attachment.name,
                };
                this.edit_user_data.signature_details.size = attachment.size;
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.edit_user_data.signature_details.signature = response.data.url;
                            this.upload_in_progress = false;
                        }
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                        this.upload_in_progress = false;
                    });
            },
            customGenderChange(data) {
                if(data.target.value == 'Custom') {
                    this.custom_gender_active = true;
                    this.edit_user_data.custom_gender = this.custom_gender_value
                }
            },
            femaleGender(data) {
                if(data.target.value == 'Female') {
                    this.edit_user_data.gender = 'Female';
                    this.edit_user_data.custom_gender = '';
                    this.custom_gender_active = false;
                }
            },
            maleGender(data) {
                if(data.target.value == 'Male') {
                    this.edit_user_data.gender = 'Male';
                    this.edit_user_data.custom_gender = '';
                    this.custom_gender_active = false;
                }
            },
            async personalInfoData() {
                this.ajax_call_in_progress = true;
                this.edit_enable = false
                try {
                    let param = this.$route.params.id ? this.$route.params.id : this.loggedInUser.user_id;
                    let response = await this.getPersonalInfo(param);
                    if (response.status_id == 1) {
                        localStorage.setItem('basic_info_data', JSON.stringify(response.response));
                        this.edit_enable = false;
                        // this.edit_tax_id = false;
                        this.custom_gender_active = false;
                        this.user_data = response.response;
                        this.stored_user_data = JSON.parse(JSON.stringify(this.user_data));
                        this.edit_user_data.first_name = this.user_data.first_name;
                        this.edit_user_data.middle_name = this.user_data.middle_name;
                        this.edit_user_data.last_name = this.user_data.last_name;
                        this.edit_user_data.date_of_birth = this.user_data.date_of_birth.split('-')[1]+'/'+this.user_data.date_of_birth.split('-')[2]+'/'+this.user_data.date_of_birth.split('-')[0];
                        this.edit_user_data.about = this.user_data.about;
                        this.edit_user_data.job_description = this.user_data.job_description;
                        this.edit_user_data.gender = this.user_data.gender;
                        this.edit_user_data.custom_gender = this.user_data.custom_gender;
                        this.custom_gender_value = JSON.parse(JSON.stringify(this.user_data.custom_gender));
                        this.edit_user_data.user_access = this.user_data.user_access;
                        if(Object.keys(this.user_data.signature_details).length > 0) {
                            this.edit_user_data.signature_details = this.user_data.signature_details
                        }
                        if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                            this.edit_user_data.gender = 'Custom'
                        }
                        this.ajax_call_in_progress = false;
                        this.stringified_response = JSON.stringify(this.create_data)
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async updateInfo() {
                try {
                    let params = {
                    };
                    params.first_name = this.edit_user_data.first_name;
                    params.middle_name = this.edit_user_data.middle_name;
                    params.last_name = this.edit_user_data.last_name;
                    if(this.edit_user_data.custom_gender.length > 0) {
                        params.gender = 'custom';
                        params.custom_gender = this.edit_user_data.custom_gender;
                    } else {
                        params.gender = this.edit_user_data.gender;
                        params.custom_gender = '';
                    }
                    params.date_of_birth = this.edit_user_data.date_of_birth.split('/')[2]+'-'+this.edit_user_data.date_of_birth.split('/')[0]+'-'+this.edit_user_data.date_of_birth.split('/')[1];
                    params.about = this.edit_user_data.about;
                    params.job_description = this.edit_user_data.job_description;
                    params.signature_details = this.edit_user_data.signature_details;
                    if(this.edit_user_data.signature_details.id == '' || this.edit_user_data.signature_details.id == undefined || this.edit_user_data.signature_details.id == null) {
                        delete params.signature_details.id
                    }
                    this.save_api_call_in_progress = true
                    let response = await this.updatePersonalInfo(params, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.left_tab = false
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.personalInfoData();
                            EventBus.$emit('personal_info_data_update')
                        }, 2000);
                        this.save_api_call_in_progress = false
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.save_api_call_in_progress = false
                    }, 2000);
                }
            }
        },
        props: [],
        beforeRouteLeave (to, from, next) {
            if (this.left_tab == true) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                // <img src='/static/images/exclamation-yellow.svg' class='d-block h-100px mx-auto mb-5'/><br>
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        // beforeRouteLeave (to, from, next) {
        //     if (this.edit_enable == true) {
        //         const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        //         if (answer) {
        //             next()
        //         } else {
        //             next(false)
        //         }
        //     } else {
        //         next()
        //     }
        // },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            });
            // if(from == "/") this.checkTwoFactorVerification();
            // next()
        },
        mounted() {
            // setTimeout(() => {
                // if(this.loggedInUser.first_login === true) {
                    this.checkTwoFactorVerification();
                // }
            // }, 1000);
            this.personalInfoData();
            localStorage.removeItem('comp_data_for_menubar');
        },
        created() {
        },
        watch: {
        },
        computed: {
            fields_not_changed(){
                if(this.stringified_response == JSON.stringify(this.create_data)){
                    this.left_tab = false
                }else{
                    this.left_tab = true
                }
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            create_data() {
                return {
                    "first_name": this.edit_user_data.first_name ,
                    "middle_name": this.edit_user_data.middle_name,
                    "last_name": this.edit_user_data.last_name, 
                    "date_of_birth": this.edit_user_data.date_of_birth,
                    "about": this.edit_user_data.about,
                    "job_description": this.edit_user_data.job_description,
                    "gender": this.edit_user_data.gender,
                    "custom_gender": this.edit_user_data.custom_gender,
                    "custom_gender_value": this.custom_gender_value,
                    "user_access": this.edit_user_data.user_access,
                    "signature_details": this.edit_user_data.signature_details,
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
        beforeDestroy() {
            localStorage.removeItem('basic_info_data');
        },
    };
</script>
<style scoped>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .new-contactView-item {
        padding: 16px 10px 16px 3px !important;
    }
    .validation{
        color: red;
    }
    .new-contact-value {
        margin-left: 0px !important;
    }
    .signature-box {
        padding: 8px;
        border-radius: 5px;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
    }
    /* .new-contactView{
        align-items: center;
    } */
    
    @media only screen and (min-width: 0px) and (max-width: 350px){
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .basic-info{
            width: 100% !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
    }
    @media only screen and (min-width:351px) and (max-width: 400px){
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .basic-info{
            width: 100% !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
    }
    @media only screen and (min-width:401px) and (max-width: 500px){
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .basic-info{
            width: 100% !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
    }
    @media only screen and (min-width:501px) and (max-width: 768px){
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .basic-info{
            width: 100% !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
    }
    .profile-content-left-text {
        width: 160px !important;
        min-width: 160px !important;
        position: relative;
        font-weight: 600;
    }
    .btn-success{
        /* background-color: #128807 !important; */
        border-radius: 5px !important;
    }
    .btn {
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0), 0px 1px 5px 0px rgba(0,0,0,0) !important;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 1px 5px 0px rgba(0,0,0,0) !important;
    }
    .multiselect {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        min-height: 40px;
        text-align: left;
        color: #303031 !important;
    }
    .btn-new-primarys {
        color: #fff;
        border-radius: 5px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-transform: none;
        font-weight: 500;
        line-height: 30px;
        box-shadow: none;
        background-color: #00448b;
        border-color: 2px solid #00448b;
        box-shadow: none !important;
        font-weight: 500;
    }
    .readonly{
        cursor: no-drop;
    }
    .active-custom{
        margin-top: -18px !important;
    }
    .profile-date-picker.disabled input{
        color: #303031 !important;

    }
    .profile-date-picker.disabled .mx-input-wrapper.disabled .mx-input.disabled input{
        color: #303031 !important;

    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>